import { render, staticRenderFns } from "./paperSetting.vue?vue&type=template&id=dc10ebe6&scoped=true&"
import script from "./paperSetting.vue?vue&type=script&lang=ts&"
export * from "./paperSetting.vue?vue&type=script&lang=ts&"
import style0 from "./paperSetting.vue?vue&type=style&index=0&id=dc10ebe6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc10ebe6",
  null
  
)

export default component.exports